





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.ovy-a /deep/ .el-table__body-wrapper {
  min-height: 220px;
}
.zl {
  text-align: left;
}
.r {
  text-align: right;
}
.basicData {
  width: 530px;
}
.BatchInvoicingManagementAddEdit {
  padding: 1rem 1rem;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
.btn-box {
  padding: 10px 0;
  width: 131px;
  margin: auto;
}
/deep/ .txtRight .el-input__inner{
  text-align: right;
}
